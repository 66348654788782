<template functional>
  <span :class="`d-inline-block bg-${props.color} text-white rounded-pill ml-2 px-2 py-0`">
    <slot />
  </span>
</template>
<script>
export default {
  name: 'ServiceTypeTag',
  props: {
    color: {
      type: String,
      required: true,
      default: 'primary'
    }
  }
}
</script>
