<template>
  <div>
    <!-- <service-type-tag v-if="types.includes(0)">
      安裝
    </service-type-tag> -->
    <service-type-tag v-if="types.includes(1)" color="info">
      Maintenance
    </service-type-tag>
    <service-type-tag v-if="types.includes(0)" color="warning">
      Repair
    </service-type-tag>
  </div>
</template>
<script>
import serviceTypeTag from '@/components/common/serviceTypeTag.vue'
export default {
  name: 'ServiceTypeTags',
  components: { serviceTypeTag },
  props: {
    types: {
      type: Array,
      required: true
    }
  }
}
</script>
