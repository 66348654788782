<template>
  <div>
    <b-row>
      <b-col cols="6">
        <status-cnt-card name="Unassigned" :cnt="pending" color="primary" />
      </b-col>
      <b-col cols="6">
        <status-cnt-card name="Assigned" :cnt="assigned.length" color="warning" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <div class="areawrap">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <h2 class="mt-3">
                Unassigned List
              </h2>
            </b-col>
          </b-row>
          <div class="scroll-list custom-scroll mt-4 d-flex flex-column bd-highlight ">
            <b-table-simple class="table-base" hover>
              <b-tbody>
                <b-tr v-for="item in unassigned" :key="item.id" @click="toService(item.id, 0)">
                  <b-td class="p-2 border-0" role="button">
                    <div class="d-flex justify-content-between ">
                      <h3>
                        {{ item.clinic_name }}
                      </h3>
                      <service-type-tags :types="[0]" />
                    </div>
                    <div class="d-flex caption">
                      {{ item.title }}
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="areawrap">
          <b-row class="d-flex justify-content-between ">
            <b-col>
              <h2 class="mt-3">
                Assigned List
              </h2>
            </b-col>
          </b-row>
          <div class="scroll-list custom-scroll pl-2 mt-4 d-flex flex-column bd-highlight">
            <b-table-simple class="table-base" hover>
              <b-tbody>
                <b-tr v-for="item in assigned" :key="item.id" @click="toService(item.id, 1)">
                  <b-td class="p-2 border-0" role="button">
                    <div class="d-flex justify-content-between">
                      <h3>
                        {{ item.clinic_name }}
                      </h3>
                      <service-type-tags :types="[0]" />
                    </div>
                    <div class="d-flex caption">
                      {{ $dayjs(item.dispatch.schedule_date).format('YYYY-MM-DD HH:mm') }} - {{ item.dispatch.technician }}
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4 areawrap">
      <b-col cols="12">
        <h2 class="mt-3">
          Complete List
        </h2>
      </b-col>
      <b-col cols="12">
        <b-table
          class="table-base"
          hover
          borderless
          :items="completed"
          :fields="fields"
          :current-page="currentPage"
          :per-page="rowPrePage"
          :tbody-tr-class="'table-pointer'"
          @row-clicked="toCompleteService"
        >
          <template #head(title)>
            TITLE
          </template>
          <template #head(type)>
            TYPE
          </template>
          <template #head(clinic_name)>
            CLINIC
          </template>
          <template #head(applied_time)>
            APPLIED TIME
          </template>
          <template #cell(type)="data">
            <service-type-tags :types="[data.item.type]" />
          </template>
          <template #cell(applied_time)="data">
            {{ $dayjs(data.item.applied_time).format('M/DD  hh:mma') }}
          </template>
        </b-table>
        <div class="d-flex justify-content-between mt-6">
          <div />
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="rowPrePage"
            align="fill"
            size="sm"
            class="my-0"
            first-number
            last-number
          />
          <row-count :total-rows="totalRows" :row-pre-page="rowPrePage" :current-page="currentPage" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import statusCntCard from '@/components/dashboard/statusCntCard'
import ServiceTypeTags from '@/components/common/serviceTypeTags'
import { getDashboard } from '@/api/partner'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'Dashboard',
  components: {
    statusCntCard,
    ServiceTypeTags,
    RowCount
  },
  data () {
    return {
      assigned: [],
      unassigned: [],
      // filterList: [],
      // detailList: [],
      completed: [],
      // scheduleFilter: 0,

      showDetail: false,
      pending: 0,

      totalRows: 0,
      currentPage: 1,
      rowPrePage: 6
    }
  },
  computed: {
    fields () {
      return ['title', 'type', 'clinic_name', 'applied_time']
    }
  },
  created () {
    // this.assigned = assigned
    // this.unassigned = unassigned
    // this.filterList = filterList
    // this.detailList = detailList
    getDashboard().then(res => {
      const data = res.data.data
      this.pending = data.pending
      this.unassigned = data.unassigned
      this.assigned = data.assigned
      this.completed = data.completed
      this.totalRows = this.completed.length
    })
  },
  methods: {
    toService (id, tab) {
      this.$router.push({ name: 'Service', params: { id: id, tab: tab } })
    },
    toCompleteService (item) {
      this.toService(item.id, 2)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~@/assets/style/variables';

.scroll-list{
  padding-right: 1rem;
  max-height: 330px;
  height: 330px;
  overflow-y: auto;
}
.card-detail{
  position: absolute;
  top:80%;
  width: 92%;
  max-height: 304px;
  overflow-y: auto;
  border-radius: 0.75rem;
      backdrop-filter: blur(10px);
      box-shadow: 10px 10px 20px 0 rgba(174, 174, 192, 0.4), inset 2px 2px 6px 0 rgba(255, 255, 255, 0.8);
      background-color: rgba(242, 243, 247, 0.4);
      z-index: 10;
      padding: 1rem;
}
.detail-item:hover{
  cursor: pointer;
  color: $primary;

}
.caption{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(84, 90, 93, 0.7);
}
</style>
