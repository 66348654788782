<template functional>
  <div class="areawrap d-flex justify-content-between">
    <h2>
      {{ props.name }}
    </h2>
    <h2 :class="`text-${props.color} pr-4`">
      {{ props.cnt }}
    </h2>
  </div>
</template>
<script>
export default {
  name: 'StatusCntCard',
  props: {
    name: {
      type: String,
      required: true
    },
    cnt: {
      type: Number,
      required: true,
      default: 0
    },
    color: {
      type: String,
      required: true,
      default: 'primary'
    }
  }
}
</script>
